import React, { useMemo, useRef, useEffect } from 'react'
import PropTypes from 'prop-types';
import { css } from '@emotion/css'
import { Editor as SlateEditor, Transforms, Text, createEditor, Range } from "slate"
import { Slate, Editable, ReactEditor, withReact, useSlate } from 'slate-react'
import { withHistory } from 'slate-history'
import isHotkey from 'is-hotkey'

import { Button, Icon, Menu, Portal } from './subcomponents'
import { isBrowser } from '../../utils'

const HOTKEYS = {
  'mod+b': 'bold',
  'mod+i': 'italic',
  'mod+u': 'underlined',
}

const Editor = (props) => {
  const {
    initialValue,
    setText,
    className
  } = props;

  const editor = useMemo(() => withHistory(withReact(createEditor())), [])


  const handleChange = (value) => {
    setText(value);
  }

  return (
    <Slate
      editor={editor}
      value={initialValue}
      onChange={value => handleChange(value)}
    >
      <HoveringToolbar />
      <Editable
        autoFocus
        className={className}
        renderLeaf={props => <Leaf {...props} />}
        placeholder="A new story begins..."
        onKeyDown={event => {
          for (const hotkey in HOTKEYS) {
            if (isHotkey(hotkey, event)) {
              event.preventDefault()
              const mark = HOTKEYS[hotkey]
              toggleFormat(editor, mark)
            }
          }
        }}
      />
    </Slate>
  )
}

const toggleFormat = (editor, format) => {
  const isActive = isFormatActive(editor, format);
  Transforms.setNodes(
    editor,
    { [format]: isActive ? null : true },
    { match: Text.isText, split: true }
  )
}

const isFormatActive = (editor, format) => {
  const marks = SlateEditor.marks(editor)
  return marks ? marks[format] === true : false
}

const Leaf = ({ attributes, children, leaf }) => {
  if (leaf.bold) {
    children = <strong>{children}</strong>
  }

  if (leaf.italic) {
    children = <em>{children}</em>
  }

  if (leaf.underlined) {
    children = <u>{children}</u>
  }

  if (leaf["heading-one"]) {
    children = <h2>{children}</h2>
  }

  if (leaf["heading-two"]) {
    children = <h3>{children}</h3>
  }

  if (leaf["block-quote"]) {
    children = <blockquote>{children}</blockquote>
  }

  return <span {...attributes}>{children}</span>
}

const HoveringToolbar = () => {
  const ref = useRef()
  const editor = useSlate()

  useEffect(() => {
    const el = ref.current
    const { selection } = editor

    if (!el) {
      return
    }

    if (
      !selection ||
      !ReactEditor.isFocused(editor) ||
      Range.isCollapsed(selection) ||
      SlateEditor.string(editor, selection) === ''
    ) {
      el.removeAttribute('style')
      return
    }

    // Some build-time hacks for `window`
    const pageYOffset = isBrowser() ? window.pageYOffset : 0;
    const pageXOffset = isBrowser() ? window.pageXOffset : 0;
    const domSelection = isBrowser() ? window.getSelection() : 0;
    const domRange = domSelection.getRangeAt(0)
    const rect = domRange.getBoundingClientRect()
    el.style.opacity = '1'
    el.style.top = `${rect.top + pageYOffset - el.offsetHeight}px`
    el.style.left = `${rect.left +
      pageXOffset -
      el.offsetWidth / 2 +
      rect.width / 2}px`
  })

  return (
    <Portal>
      <Menu
        ref={ref}
        className={css`
          padding: 6px 7px 6px;
          position: absolute;
          z-index: 1;
          top: -10000px;
          left: -10000px;
          margin-top: -6px;
          opacity: 0;
          background-color: #222;
          border-radius: 4px;
          transition: opacity 0.75s;
        `}
      >
        <FormatButton format="bold" icon="format_bold" />
        <FormatButton format="italic" icon="format_italic" />
        <FormatButton format="underlined" icon="format_underlined" />
        <FormatButton format="heading-one" icon="looks_one" />
        <FormatButton format="heading-two" icon="looks_two" />
        <FormatButton format="block-quote" icon="format_quote" />
      </Menu>
    </Portal>
  )
}

const FormatButton = ({ format, icon }) => {
  const editor = useSlate()
  return (
    <Button
      reversed
      active={isFormatActive(editor, format)}
      onMouseDown={event => {
        event.preventDefault()
        toggleFormat(editor, format)
      }}
    >
      <Icon>{icon}</Icon>
    </Button>
  )
}

Editor.propTypes = {
  className: PropTypes.string,
  initialValue: PropTypes.arrayOf(PropTypes.object)
}

Editor.defaultProps = {
  className: undefined,
}

export default Editor;
