import { css } from '@emotion/react';

const styles = (props) => {
  const {
    theme: {
      spacingL,
      fontFamilyPrimary,
      typographyHeader2 = {}, // TODO: fix this build-time hack
      typographyHeader3 = {}, // TODO: fix this build-time hack
      colorText,
    }
  } = props;

  return css`
    font-family: ${fontFamilyPrimary};
    margin-top: ${spacingL};
    color: ${colorText};

    h2 {
      font-family: ${typographyHeader2.fontFamily};
      font-size: ${typographyHeader2.fontSize};
      font-weight: ${typographyHeader2.fontWeight};
    }

    h3 {
      font-family: ${typographyHeader3.fontFamily};
      font-size: ${typographyHeader3.fontSize};
      font-weight: ${typographyHeader3.fontWeight};
    }

    blockquote {
      font-family: ${fontFamilyPrimary};
    }
  `
}

export default styles;
